import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import '../../global.css'
import favicon from '../../favicon.png'
import Nav from '../../navigation.js'
import Footer from '../../footer.js'

import vitalBg from '../../vitalBg.jpg'
import eskin from '../../eskin.png'
import app from '../../app.png'

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  .sectionHeader {
    font-size: 1.5em;
    padding: 30px 0;
  }
`

const Hero = styled.header`
  text-align: left;
  position: relative;
  height: 50%;
  background-image: url(${vitalBg});
  background-size: cover;
  background-position: center;
  @media (max-width: 800px) {
    text-align: center;
  }
  .wrapper {
    position: absolute;
    top: 50%;
    width: 100%;
    max-width: 600px;
    padding-left: 10%;
    transform: translateY(-50%);
    @media (max-width: 800px) {
      padding: 0;
    }
    h1 {
      font-size: 3em;
      @media (max-width: 800px) {
        font-size: 2em;
      }
    }
    span {
      font-family: nunitoReg, sans-serif;
    }
  }
`
const Details = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 80px 0 0;
  @media (max-width: 800px) {
    text-align: center;
    padding: 40px 0;
  }
  .details {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    @media (max-width: 800px) {
      flex-wrap: wrap;
    }
    div {
      flex: 0 50%;
      border-radius: 14px;
      @media (max-width: 800px) {
        flex: 0 100%;
      }
    }
  }
  h1 {
    padding-bottom: 30px;
  }

  p {
    font-family: nunitoReg, sans-serif;
    padding: 0 0 40px;
    width: 90%;
    max-width: 800px;
    opacity: 0.8;
    @media (max-width: 800px) {
      font-size: 1em;
      text-align: center;
      width: 100%;
    }
  }

  a {
    font-family: nunitoBold, sans-serif;
    color: white;
    text-transform: uppercase;
    opacity: 1;
  }
  .video {
    width: 100%;
    border-radius: 14px;
    padding: 40px 0 0;
    @media (max-width: 800px) {
      padding: 0;
    }
    img {
      border-radius: 14px;
    }
    iframe {
      width: 100%;
      height: 620px;
      @media (max-width: 800px) {
        height: 260px;
        padding: 40px 0 0;
      }
    }
  }
  .images {
    background: white;
    padding: 0 20px;
    img {
      width: 100%;
      border-radius: 14px;
    }
  }
`

export default () => (
  <Container>
    <Helmet>
      <title>Splendo</title>
      <meta charSet="utf-8" />
      <meta name="description" content="Splendo" />
      <link rel="icon" type="image/png" href={favicon} sizes="50x50" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    </Helmet>

    <Nav />

    <Hero>
      <div className="wrapper">
        <h1>
          Vital Signs <br /> Smart Shirt
        </h1>
      </div>
    </Hero>

    <Details>
      <h1 className="sectionHeader">About Our Vital Signs Smart Shirt</h1>
      <div className="details">
        <div>
          <p>
            Splendo NANO TECH shirt is a result of Splendo's participation in the &nbsp;
            <a href="https://www.xprize.org/prizes/tricorder" alt="XPRIZE" target="_blank" rel="noopener noreferrer">
              Qualcomm Tricorder XPRIZE
            </a>
            . This is a smart wearable consisting of a shirt and software to monitor and measure vital signs using non-invasive technologies.
          </p>

          <p>
            The shirt contains integrated nanosensors and DLS (Dynamic Light Scattering) sensors that continuously and non-invasively measure pulse,
            blood flow, blood velocity, coagulation, vascular health and body movement.
          </p>

          <p>
            The raw signals from the sensors translate into novel indices of cardiovascular risks, such as endothelial function, global coagulation
            status of the blood, red blood cells aggregation rate and fibrinogen concentration in blood. In addition, the connected SPLENDO software
            platform can continuously and non-invasively measure a set of physiological parameters such as respiratory rate, systolic blood pressure
            trends, cardiac contractility, cardiac valve dysfunction and Traube-Hering-Mayer oscillations by analyzing the high resolution blood pulse
            wave.
          </p>

          <p>
            By aggregating all these measurements NANO TECH can detect health perturbations related to Cardiac, Vascular, Respiratory and Neurological
            pathologies.
          </p>
        </div>
        <div className="images">
          <img src={app} alt="Splendo Nanotech App" />

          <img src={eskin} alt="Splendo Nanotech eSkin" />
        </div>
      </div>

      <div className="video">
        <iframe
          src="https://player.vimeo.com/video/154298780?color=ffffff&title=0&byline=0&portrait=0"
          frameborder="0"
          title="Splendo Nanotech"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
        />
      </div>
      <script src="https://player.vimeo.com/api/player.js" />
    </Details>

    <Footer />
  </Container>
)
